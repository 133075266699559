<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M14 14L11.0093 11.004M12.6666 7.00004C12.6666 8.50293 12.0696 9.94427 11.0069 11.007C9.94421 12.0697 8.50287 12.6667 6.99998 12.6667C5.49709 12.6667 4.05575 12.0697 2.99304 11.007C1.93034 9.94427 1.33331 8.50293 1.33331 7.00004C1.33331 5.49715 1.93034 4.05581 2.99304 2.9931C4.05575 1.9304 5.49709 1.33337 6.99998 1.33337C8.50287 1.33337 9.94421 1.9304 11.0069 2.9931C12.0696 4.05581 12.6666 5.49715 12.6666 7.00004V7.00004Z"
      stroke="#191617"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
